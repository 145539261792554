var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "text-field" }, [
    _vm.label
      ? _c(
          "label",
          { staticClass: "label", attrs: { for: `input-${_vm.label}` } },
          [_vm._v(_vm._s(_vm.label))]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "input-text-group" },
      [
        _vm.type === "email"
          ? _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/img/icon/email.svg"),
                alt: "email",
              },
            })
          : _vm.type === "password"
          ? _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/img/icon/lock.svg"),
                alt: "email",
              },
            })
          : _vm._e(),
        !_vm.rows
          ? _c("input-text", {
              staticClass: "input",
              attrs: {
                label: _vm.placeholder,
                id: _vm.id || `input-${_vm.label}`,
                value: _vm.value,
                name: _vm.name,
                type: _vm.passwordVisible ? "text" : _vm.inputType,
                maxlength: _vm.maxlength,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event)
                },
              },
            })
          : _c("input-textarea", {
              staticClass: "input",
              attrs: {
                rows: _vm.rows,
                label: _vm.placeholder,
                id: _vm.id || `input-${_vm.label}`,
                value: _vm.value,
                name: _vm.name,
                maxlength: _vm.maxlength,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event)
                },
              },
            }),
        _vm.type === "password"
          ? _c(
              "button",
              {
                staticClass: "toggle-button",
                attrs: { tabindex: "-1", type: "button" },
                on: { click: _vm.toggleVisible },
              },
              [
                _vm.passwordVisible
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/password-visibility.svg"),
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/password-visibility_off.svg"),
                      },
                    }),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }